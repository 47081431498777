import React from "react";
import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Warning } from "@mui/icons-material";

const styles = {
  container: {
    textAlign: "center",
    marginTop: "100px",
  },
  icon: {
    fontSize: "200px",
    marginBottom: "20px",
    color: "#FF9800",
  },
  heading: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
  },
  text: {
    fontSize: "18px",
    marginBottom: "20px",
    color: "#555",
  },
  button: {
    backgroundColor: "#0A66C2",
    color: "#FFF",
    fontWeight: "bold",
    borderRadius: "5px",
    padding: "10px 20px",
    textDecoration: "none",
    marginBottom: "80px",
    boxShadow: "none",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#094771",
    },
  },
};

const SomethingWentWrong = () => {
  return (
    <div style={styles.container}>
      <Warning style={styles.icon} />
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        style={styles.heading}
      >
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1" gutterBottom style={styles.text}>
        We're sorry, but there was an unexpected error. Please try again later.
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/semua_video"
        size="large"
        style={styles.button}
      >
        Go to SS6 Wiki
      </Button>
    </div>
  );
};

export default SomethingWentWrong;
