import React, { useState, useEffect } from "react";
import $ from "jquery";
import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Alert,
  Checkbox,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const LoginPage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false); // State untuk mengontrol tampilan loader
  const [showPassword, setShowPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
  const [selectedSite, setSelectedSite] = useState("");

  const backgroundImageList = ["1.jpg", "2.jpg", "4.jpg", "3.jpg", "5.jpg"];

  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedPassword = localStorage.getItem("password");
    const storedRememberMe = localStorage.getItem("rememberMe");

    if (storedRememberMe === "true" && storedUsername && storedPassword) {
      setUsername(storedUsername);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImageIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImageList.length
      );
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [backgroundImageList.length]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setLoginError("");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setLoginError("");
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleLogin = (site) => {
    if (site === "") {
      setLoginError("Login gagal, Anda belum melengkapi site.");
    } else {
      setLoading(true);
      $.ajax({
        url: `https://api.${site}/ppa-employee-api/api/cico/integratedLogin`,
        method: "POST",
        data: {
          nrp: username,
          password: password,
          app_module: "PPA Dashboard",
        },
        success: (data) => {
          // console.log(data);
          if (data.message === "Login success") {
            const token = data.token;
            sessionStorage.setItem("token", token);
            // navigate("/PPATeam");
            props.setIsUserLoggedIn(true);
            if (rememberMe) {
              localStorage.setItem("username", username);
              localStorage.setItem("password", password);
              localStorage.setItem("rememberMe", true);
            } else {
              localStorage.removeItem("username");
              localStorage.removeItem("password");
              localStorage.removeItem("rememberMe");
            }
          }
          setTimeout(() => {
            setLoading(false);
            navigate("/semua_video");
          }, 1000);
        },
        error: (data) => {
          if (
            data.responseJSON.message === "Login failed, incorrect password"
          ) {
            setLoginError("Login Gagal, Password salah");
          } else if (
            data.responseJSON.message === "Login failed, user not found"
          ) {
            setLoginError(
              "Login gagal, Anda tidak memiliki izin atau NRP yang tidak valid."
            );
          }

          setLoading(false);
        },
      });
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSiteChange = (event) => {
    setSelectedSite(event.target.value);
  };

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#e50914",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(${require("../assets/" +
            backgroundImageList[backgroundImageIndex])})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          component={Paper}
          elevation={3}
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "#ffffff",
            maxWidth: "400px",
            width: "100%",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Masuk SS6 Wiki
          </Typography>
          <TextField
            id="username"
            label="NRP"
            variant="filled"
            value={username}
            onChange={handleUsernameChange}
            style={{ marginBottom: "10px", width: "100%" }}
            InputProps={{
              style: {
                color: "#ffffff",
                backgroundColor: "#333333",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#ffffff",
              },
            }}
          />
          <TextField
            id="password"
            label="Password"
            variant="filled"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            style={{ marginBottom: "20px", width: "100%" }}
            InputProps={{
              style: {
                color: "#ffffff",
                backgroundColor: "#333333",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleTogglePasswordVisibility}
                    onMouseDown={(e) => e.preventDefault()}
                    disableFocusRipple // Menonaktifkan latar belakang saat hover
                  >
                    {showPassword ? (
                      <Typography variant="body2" style={{ color: "#808080" }}>
                        Sembunyikan
                      </Typography>
                    ) : (
                      <Typography variant="body2" style={{ color: "#808080" }}>
                        Tampilkan
                      </Typography>
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
                color: "#ffffff",
              },
            }}
          />
          <FormControl
            variant="filled"
            style={{
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <Select
              id="site"
              value={selectedSite}
              onChange={handleSiteChange}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                Pilih Site
              </MenuItem>
              <MenuItem value="ppa-HO.net">HO</MenuItem>
              <MenuItem value="amm-abp.net">ABP</MenuItem>
              <MenuItem value="ppa-adw.net">ADW</MenuItem>
              <MenuItem value="ppa-amc.net">AMC</MenuItem>
              <MenuItem value="ppa-ba.net">BA</MenuItem>
              <MenuItem value="ppa-bcp.net">BCP</MenuItem>
              <MenuItem value="ppa-bib.net">BIB</MenuItem>
              <MenuItem value="ppa-bge.net">BGE</MenuItem>
              <MenuItem value="ppa-hsm.net">HSM</MenuItem>
              <MenuItem value="ppa-ipt.net">IPT</MenuItem>
              <MenuItem value="ppa-mhu.net">MHU</MenuItem>
              <MenuItem value="ppa-mlp.net">MLP</MenuItem>
              <MenuItem value="ppa-mifa.net">MIFA</MenuItem>
              <MenuItem value="ppa-mip.net">MIP</MenuItem>
              <MenuItem value="ppa-sks.net">SKS</MenuItem>
              <MenuItem value="ppa-swi.net">SWI</MenuItem>
              <MenuItem value="ppa-pik.net">PIK</MenuItem>
            </Select>
          </FormControl>
          {loginError && <Alert severity="warning">{loginError}</Alert>}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleLogin(selectedSite)}
            sx={{ width: "100%", marginTop: "10px" }}
          >
            {" "}
            Masuk
          </Button>
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
            }
            label="Ingat Saya"
            sx={{ marginRight: "auto" }}
          />
          <Typography
            variant="body2"
            component="p"
            sx={{
              marginLeft: "auto",
              marginTop: "-30px",
              tDecorationColor: "#ffffff",
              // },
              color: "#808080",
              cursor: "pointer",
            }}
            onClick={handleOpenDialog}
          >
            <Tooltip title="Anda belum bisa Login?">
              <span>Bantuan?</span>
            </Tooltip>
            {/* <a href="/" style={{ color: "inherit", textDecoration: "inherit" }}>
              Bantuan?
            </a> */}
          </Typography>
        </Box>
      </Box>
      {/* Loader saat login berhasil */}
      <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backdropFilter: "blur(10px)",
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      {/* Popup Bantuan */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Anda Belum bisa login?</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Untuk login, anda dapat menggunakan <strong>NRP</strong> dan{" "}
            <strong>Password</strong> yang telah terdaftar pada{" "}
            <strong>System PPA</strong>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Tutup</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default LoginPage;
