import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Skeleton,
  Tooltip,
} from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YouTube from "react-youtube";
import "./styles/TutorialPage.css";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const TutorialPage = ({ searchKeyword, listVideo, isAuth }) => {
  const [showAllVideos, setShowAllVideos] = useState(false);
  const [welcomeSnackbarOpen, setWelcomeSnackbarOpen] = useState(false);
  const [filteredVideo, setFilteredVideo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  // useEffect(() => {
  //   let filteredVideo = listVideo.filter(
  //     (video) =>
  //       video.description.toLowerCase().includes(searchKeyword.toLowerCase()) ||
  //       video.title.toLowerCase().includes(searchKeyword.toLowerCase())
  //   );
  //   setFilteredVideo(showAllVideos ? filteredVideo : filteredVideo.slice(0, 6));
  // }, [listVideo, searchKeyword, showAllVideos]);

  useEffect(() => {
    let filteredVideo = listVideo.filter((video) => {
      const description = video.description || "";
      const title = video.title || "";

      return (
        description.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        title.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    });
    setFilteredVideo(showAllVideos ? filteredVideo : filteredVideo.slice(0, 6));
  }, [listVideo, searchKeyword, showAllVideos]);

  useEffect(() => {
    setWelcomeSnackbarOpen(true);

    const token = sessionStorage.getItem("token");
    if (token === null || token === "") {
      navigate("/");
    }

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);

  const handleWelcomeSnackbarClose = () => {
    setWelcomeSnackbarOpen(false);
  };

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  const handleSeeAllVideos = () => {
    setShowAllVideos(true);
  };

  const handleHideVideos = () => {
    setShowAllVideos(false);
  };

  const handleDownloadPDF = async (file, title) => {
    try {
      const link = document.createElement("a");
      link.href = `https://storage.ppa-mhu.net/tutorial/${file}`;
      link.download = `${title}.pdf`;
      link.target = "_blank";
      link.click();

      console.log("File PDF berhasil diunduh:", title);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDeleteVideo = (videoId) => {
    const confirmDelete = window.confirm(
      "Apakah Anda yakin ingin menghapus video?"
    );

    if (confirmDelete) {
      axios
        .delete(`https://api40.ppa-mhu.net/v1/destroy/tutorla/?id=${videoId}`)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error("Gagal hapus:", error);
        });
    }
  };

  // useEffect(() => {
  //   const fetchVideoData = async () => {
  //     try {
  //       const videoData = await Promise.all(
  //         listVideo.map(async (video) => {
  //           // Ekstrak ID video dari URL YouTube
  //           const videoId = getYouTubeVideoId(video.urlVideos);

  //           // Ambil statistik video menggunakan YouTube Data API
  //           const response = await axios.get(
  //             `https://www.googleapis.com/youtube/v3/videos?key=AIzaSyDFo6vL_gxZgPZtO3HTu_k-qfZHelzMXJA&part=snippet,statistics&id=${videoId}`
  //           );

  //           const videoStatistics = response.data.items[0].statistics;
  //           const viewCount = videoStatistics.viewCount;
  //           const lastUploadDate = response.data.items[0].snippet.publishedAt;

  //           return {
  //             ...video,
  //             viewCount,
  //             lastUploadDate,
  //           };
  //         })
  //       );

  //       setFilteredVideo(showAllVideos ? videoData : videoData.slice(0, 6));
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching video data:", error);
  //     }
  //   };

  //   fetchVideoData();
  // }, [listVideo, showAllVideos]);

  const getYouTubeVideoId = (url) => {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:shorts\/)?watch\?.*v=|youtu\.be\/)([\w-]{11})(?:\S+)?$/;
    const match = url.match(regExp);
    return match && match[1] ? match[1] : null;
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        maxWidth: "1400px",
        margin: "0 auto",
      }}
    >
      {isLoading ? (
        <>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className="tutorial-card" elevation={3}>
                <Skeleton variant="rounded" height={150} />
                <CardContent>
                  <Typography variant="h6" component="div">
                    <Skeleton height={40} />
                  </Typography>
                  <Typography variant="body2">
                    <Skeleton width={150} height={30} />
                  </Typography>
                </CardContent>
                <div
                  style={{
                    display: "flex",
                    marginRight: "10px",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    style={{
                      marginRight: "5px",
                      width: "100%",
                    }}
                  >
                    <Skeleton width={150} height={36} />
                  </Button>
                  <Button
                    style={{
                      marginLeft: "5px",
                      width: "100%",
                    }}
                  >
                    <Skeleton width={150} height={36} />
                  </Button>
                </div>
              </Card>
            </Grid>
          ))}
        </>
      ) : (
        <>
          {filteredVideo.map((video) => (
            <Grid item xs={12} sm={6} md={4} key={video.id}>
              <Card className="tutorial-card" elevation={3}>
                <YouTube
                  videoId={getYouTubeVideoId(video.urlVideos)}
                  opts={opts}
                />
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    className="tutorial-title"
                  >
                    {video.title}
                  </Typography>
                  {/* <Typography variant="body2" className="tutorial-description">
                    {video.viewCount} x Ditonton - Diunggah:{" "}
                    {new Date(video.lastUploadDate).toLocaleDateString(
                      "id-ID",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }
                    )}
                  </Typography> */}
                  <Typography variant="body2" className="tutorial-description">
                    {video.description}
                  </Typography>
                </CardContent>
                <div
                  style={{
                    display: "flex",
                    marginRight: "10px",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<BookmarkBorderOutlinedIcon />}
                    onClick={() => handleDownloadPDF(video.file, video.title)}
                    className="download-button"
                    style={{
                      marginRight: "5px",
                      width: "100%",
                      borderRadius: "20px",
                      color: "#0F0F0F",
                      borderColor: "#F2F2F2",
                      background: "#F2F2F2",
                      transition:
                        "background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#DADADA";
                      e.currentTarget.style.borderColor = "#EAEAEA";
                      e.currentTarget.style.boxShadow =
                        "0px 5px 5px rgba(0, 0, 0, 0.1)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#F2F2F2";
                      e.currentTarget.style.borderColor = "#F2F2F2";
                      e.currentTarget.style.boxShadow = "none";
                    }}
                  >
                    Download PDF
                  </Button>

                  {isAuth && (
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteVideo(video.id)}
                      className="delete-button"
                      style={{
                        marginLeft: "5px",
                        width: "100%",
                        borderRadius: "20px",
                        color: "#fff",
                        borderColor: "#FF4444",
                        background: "#FF8888",
                        transition:
                          "background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#FF4444";
                        e.currentTarget.style.borderColor = "#FF4444";
                        e.currentTarget.style.boxShadow =
                          "0px 5px 5px rgba(0, 0, 0, 0.1)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "#FF8888";
                        e.currentTarget.style.borderColor = "#FF4444";
                        e.currentTarget.style.boxShadow = "none";
                      }}
                    >
                      Hapus
                    </Button>
                  )}
                </div>
              </Card>
            </Grid>
          ))}
        </>
      )}
      {!showAllVideos && (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <IconButton
            onClick={handleSeeAllVideos}
            style={{
              border: "2px solid #ccc",
            }}
          >
            <Tooltip title="Tampilkan Semua">
              <ExpandMoreIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      )}
      {showAllVideos && (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <IconButton
            onClick={handleHideVideos}
            style={{
              border: "2px solid #ccc",
            }}
          >
            <Tooltip title="Sembunyikan">
              <ExpandLessIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      )}
      {/* <Snackbar
        open={welcomeSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleWelcomeSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "left" }}
      >
        <Alert onClose={handleWelcomeSnackbarClose} severity="success">
          Selamat datang di <strong>SS6 Wiki</strong>
        </Alert>
      </Snackbar> */}
    </Grid>
  );
};

export default TutorialPage;
