import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  TextField,
  Box,
  Tab,
  Tabs,
  InputAdornment,
  IconButton,
  Typography,
  CssBaseline,
  createTheme,
  ThemeProvider,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Alert,
  FormControl,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import TtPPATeam from "./components/TtPPATeam";
import TtPageDashPPATeam from "./components/TtPageDashPPATeam";
import TtPageDashSWI from "./components/TtPageDashSWI";
import TtPageDashFMS from "./components/TtPageDashFMS";
import TtInstalasiDevice from "./components/TtInstalasiDevice";
import LoginPage from "./components/LoginPage";
import FileNotFound from "./components/FileNotFound";
import ppaTeamLogo from "./assets/ppa-team-logo.png";
import ppateamlogo2 from "./assets/ppa-team-logo2.png";
import mobileLogo from "./assets/lg_ppa.png";
import SearchIcon from "@mui/icons-material/Search";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import playstoreImage from "./assets/google-playstore.webp";
import appstoreImage from "./assets/ios-appstore.webp";
import SendIcon from "@mui/icons-material/Send";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import jwtDecode from "jwt-decode";
import axios from "axios";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import DeleteIcon from "@mui/icons-material/Delete";

import "./App.css";

const App = ({ open, onClose }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  // const [activeTab, setActiveTab] = useState(0);
  const [darkMode, setDarkMode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [isLoggedIn, setIsUserLoggedIn] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [newVideoUrl, setNewVideoUrl] = useState("");
  const [newVideoTitle, setNewVideoTitle] = useState("");
  const [newVideoPDF, setNewVideoPDF] = useState("");
  const [newVideoDescription, setNewVideoDescription] = useState("");
  const [videos, setVideos] = useState([]);
  const [isFormIncomplete, setIsFormIncomplete] = useState(false);
  const [gMenu, setGmenu] = useState(null);
  const [listVideo, setListVideo] = useState([]);
  const [newMenu, setNewMenu] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isAddingVideo, setIsAddingVideo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleToggleInfo = () => {
    setShowInfo(false);
  };

  const handleSearch = (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("activeTab");
    if (savedTab) {
      setActiveTab(parseInt(savedTab, 20));
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    localStorage.setItem("activeTab", newValue.toString());
  };

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const handleLogout = () => {
    setLogoutDialogOpen(true);
    setToken(null);
    setIsUserLoggedIn(false);
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  const handleLogoutConfirm = () => {
    setLogoutDialogOpen(false);
  };

  const textStyle = {
    fontFamily: "Poppins, sans-serif",
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  const getData = () => {
    axios
      .get("https://api40.ppa-mhu.net/v1/list/tutorial")
      .then((response) => setListVideo(response.data.data.listTutorial));
  };

  const handleAddVideo = () => {
    const formData = new FormData();
    formData.append("title", newVideoTitle);
    formData.append("description", newVideoDescription);
    formData.append("urlVideos", newVideoUrl);
    formData.append("fileAttache", newVideoPDF);
    formData.append("menu", newMenu);

    if (
      !newVideoTitle ||
      !newVideoDescription ||
      // !newVideoUrl ||
      !newVideoPDF ||
      !newMenu
    ) {
      setIsFormIncomplete(true);
      setTimeout(() => {
        setIsFormIncomplete(false);
      }, 3000);
      return;
    }

    setIsAddingVideo(true);

    setIsButtonVisible(false); // Menghilangkan tombol saat proses

    setIsButtonLoading(true);

    // Kirim data video ke API menggunakan metode POST dan FormData
    fetch("https://api40.ppa-mhu.net/v1/new/tutorial", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error adding video");
        }
      })
      .then((data) => {
        setVideos((prevVideos) => [...prevVideos, data]);
        setNewVideoUrl("");
        setNewVideoTitle("");
        setNewVideoPDF("");
        setNewVideoDescription("");
        setNewMenu("");
        window.location.reload();
      })
      .catch((error) => {
        console.error("gagal ambil video", error);
      })
      .finally(() => {
        setIsButtonVisible(true); // Menampilkan kembali tombol setelah proses selesai
        setIsButtonLoading(false); // Menonaktifkan animasi loading tombol
      });

    setTimeout(() => {
      setIsAddingVideo(false);
    }, 100000);
  };

  const handleCloseAlert = () => {
    setIsFormIncomplete(false);
  };

  useEffect(() => {
    getData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (token !== null && token !== "") {
      setIsUserLoggedIn(true);
    }
  }, [token]);

  useEffect(() => {
    if (isLoggedIn) {
      const token = sessionStorage.getItem("token");
      const user = jwtDecode(token);
      setGmenu(user);
      if (
        user.nrp === "20000606" ||
        user.nrp === "19019548" ||
        user.nrp === "20000257"
      ) {
        setIsAuth(true);
      }
    }
  }, [isLoggedIn]);

  const buttonStyle = {
    color: darkMode ? "#f9f9f9" : "#1976d2",
    padding: "6px 12px",
    marginLeft: "10px",
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      {/* <LoginPage env={process.env} /> */}
      <Router>
        <div>
          <AppBar
            position="static"
            elevation={0}
            style={{
              display: isLoggedIn ? "block" : "none",
              backgroundColor: darkMode ? "#333" : "#f9f9f9",
              color: "black",
              border: "30px",
            }}
          >
            <Toolbar>
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                <img
                  // src={isMobile ? mobileLogo : ppaTeamLogo}
                  src={
                    isMobile
                      ? mobileLogo
                      : darkMode
                      ? ppateamlogo2
                      : ppaTeamLogo
                  }
                  alt="PPA Team"
                  height={40}
                />
              </Link>
              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="search"
                  label="Cari Tutorial"
                  variant="outlined"
                  size="small"
                  value={searchKeyword}
                  onChange={handleSearch}
                  className="search-input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: "30px",
                    },
                  }}
                />
                <Tooltip title={darkMode ? "Mode Terang" : "Mode Gelap"}>
                  <IconButton onClick={handleDarkModeToggle}>
                    {darkMode ? (
                      <DarkModeOutlinedIcon style={{ color: "grey" }} />
                    ) : (
                      <LightModeIcon style={{ color: "grey" }} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Logout">
                  <IconButton onClick={handleLogout}>
                    <ExitToAppIcon style={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Toolbar>
          </AppBar>
          <Routes>
            <Route
              path="/"
              element={<LoginPage setIsUserLoggedIn={setIsUserLoggedIn} />}
            />
            {/* <Route path="*" component={FileNotFound} /> */}
            <Route path="*" element={<FileNotFound />} />
            {/* tampilkan semua video */}
            <Route
              path="/semua_video"
              element={
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0",
                      marginBottom: "0",
                      boxShadow: "rgba(0,0,0,0.1) 0px 8px 8px -8px",
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#blue",
                        },
                      }}
                    >
                      <Tab
                        label="Semua Video"
                        component={Link}
                        to="/semua_video"
                        selected={activeTab === 0}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="PPA Team"
                        component={Link}
                        to="/PPATeam"
                        selected={activeTab === 1}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard PPA"
                        component={Link}
                        to="/dashboard_PPA_Team"
                        selected={activeTab === 2}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard SWI"
                        component={Link}
                        to="/dashboard_SWI"
                        selected={activeTab === 3}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard FMS"
                        component={Link}
                        to="/dashboard_FMS"
                        selected={activeTab === 4}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Instalasi Device"
                        component={Link}
                        to="/instalasi_device"
                        selected={activeTab === 5}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  {isFormIncomplete && (
                    <Alert severity="error" onClose={handleCloseAlert}>
                      Mohon lengkapi semua field sebelum menambahkan video.
                    </Alert>
                  )}
                  {/* Tambah Playlist Tutorial di instalasi device */}
                  {isAuth ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0px",
                        maxWidth: "1350px",
                        margin: "20px auto 0",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        padding: "20px",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="h6" color="textSecondary">
                            Tambah Playlist Tutorial{" "}
                            <Tooltip title="Form penambahan playlist hanya tersedia pada user yang di izinkan saja.">
                              <IconButton color="primary" size="small">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan URL video Youtube yang valid"
                            followCursor
                          >
                            <TextField
                              label="Tambah tutorial URL"
                              variant="outlined"
                              size="small"
                              value={newVideoUrl}
                              onChange={(e) => {
                                setNewVideoUrl(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip title="Masukkan judul tutorial" followCursor>
                            <TextField
                              label="Tambah Judul"
                              variant="outlined"
                              size="small"
                              value={newVideoTitle}
                              onChange={(e) => {
                                setNewVideoTitle(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Unggah file PDF terkait tutorial"
                            followCursor
                          >
                            <label htmlFor="upload-pdf">
                              <input
                                id="upload-pdf"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setNewVideoPDF(file);
                                    setIsFormIncomplete(false);
                                  }
                                }}
                              />
                              <Button
                                variant="outlined"
                                component="span"
                                fullWidth
                              >
                                Upload File PDF
                              </Button>
                            </label>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan deskripsi tutorial"
                            followCursor
                          >
                            <TextField
                              label="Tambah Deskripsi"
                              variant="outlined"
                              size="small"
                              value={newVideoDescription}
                              onChange={(e) => {
                                setNewVideoDescription(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              // onChange={(e) =>
                              //   setNewVideoDescription(e.target.value)
                              // }
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Mau kamu tambah di menu apa?"
                            followCursor
                          >
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                labelId="menu-label"
                                id="menu-select"
                                value={newMenu || "0"}
                                size="small"
                                displayEmpty
                                fullWidth
                                onChange={(e) => {
                                  setNewMenu(e.target.value);
                                  setIsFormIncomplete(false);
                                }}
                              >
                                <MenuItem value="0" disabled>
                                  Pilih Menu
                                </MenuItem>
                                <MenuItem value="1">PPA Team</MenuItem>
                                <MenuItem value="2">Dashboard PPA</MenuItem>
                                <MenuItem value="3">Dashboard SWI</MenuItem>
                                <MenuItem value="4">Dashboard FMS</MenuItem>
                                <MenuItem value="5">Instalasi Device</MenuItem>
                              </Select>
                            </FormControl>
                          </Tooltip>
                        </Grid>
                        {isButtonVisible && (
                          <Grid item xs={12} sm={6} md={4}>
                            <Tooltip title="Tambah tutorial">
                              <Button
                                variant="contained"
                                onClick={handleAddVideo}
                                size="small"
                                fullWidth
                                endIcon={<SendIcon style={{ fontSize: 28 }} />}
                                disabled={isButtonLoading}
                              >
                                {isButtonLoading ? "Loading..." : "Tambahkan"}
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  ) : (
                    false
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Aplikasi PPA Team
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan aplikasi PPA Team">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                  <TtPPATeam
                    searchKeyword={searchKeyword}
                    listVideo={listVideo.filter((video) => video.menu == "1")}
                    isAuth={isAuth}
                  />
                  <hr
                    style={{
                      border: "none",
                      borderTop: "2px solid #ccc",
                      margin: "20px auto",
                      width: "90%",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Dashboard PPA
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan Dashboard PPA">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>

                  {
                    <TtPageDashPPATeam
                      searchKeyword={searchKeyword}
                      listVideo={listVideo.filter((video) => video.menu == "2")}
                      isAuth={isAuth}
                    />
                  }
                  <hr
                    style={{
                      border: "none",
                      borderTop: "2px solid #ccc",
                      margin: "20px auto",
                      width: "90%",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Dashboard SWI
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan Dashboard SWI">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>

                  {
                    <TtPageDashSWI
                      searchKeyword={searchKeyword}
                      listVideo={listVideo.filter((video) => video.menu == "3")}
                      isAuth={isAuth}
                    />
                  }
                  <hr
                    style={{
                      border: "none",
                      borderTop: "2px solid #ccc",
                      margin: "20px auto",
                      width: "90%",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Dashboard FMS
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan Dashboard FMS">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>

                  {
                    <TtPageDashFMS
                      searchKeyword={searchKeyword}
                      listVideo={listVideo.filter((video) => video.menu == "4")}
                      isAuth={isAuth}
                    />
                  }
                  <hr
                    style={{
                      border: "none",
                      borderTop: "2px solid #ccc",
                      margin: "20px auto",
                      width: "90%",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Instalasi Device
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan Instalasi Device">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>

                  {
                    <TtInstalasiDevice
                      searchKeyword={searchKeyword}
                      listVideo={listVideo.filter((video) => video.menu == "5")}
                      isAuth={isAuth}
                    />
                  }
                </div>
              }
            />
            {/* tutorial ppa team */}
            <Route
              path="/PPATeam"
              element={
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0",
                      marginBottom: "0",
                      boxShadow: "rgba(0,0,0,0.1) 0px 8px 8px -8px",
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#blue",
                        },
                      }}
                    >
                      <Tab
                        label="Semua Video"
                        component={Link}
                        to="/semua_video"
                        selected={activeTab === 0}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="PPA Team"
                        component={Link}
                        to="/PPATeam"
                        selected={activeTab === 1}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard PPA"
                        component={Link}
                        to="/dashboard_PPA_Team"
                        selected={activeTab === 2}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard SWI"
                        component={Link}
                        to="/dashboard_SWI"
                        selected={activeTab === 3}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard FMS"
                        component={Link}
                        to="/dashboard_FMS"
                        selected={activeTab === 4}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Instalasi Device"
                        component={Link}
                        to="/instalasi_device"
                        selected={activeTab === 5}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  {isFormIncomplete && (
                    <Alert severity="error" onClose={handleCloseAlert}>
                      Mohon lengkapi semua field sebelum menambahkan video.
                    </Alert>
                  )}
                  {/* Tambah Playlist Tutorial di ppa team */}
                  {isAuth ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0px",
                        maxWidth: "1350px",
                        margin: "20px auto 0",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        padding: "20px",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="h6" color="textSecondary">
                            Tambah Playlist Tutorial{" "}
                            <Tooltip title="Form penambahan playlist hanya tersedia pada user yang di izinkan saja.">
                              <IconButton color="primary" size="small">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan URL video Youtube yang valid"
                            followCursor
                          >
                            <TextField
                              label="Tambah tutorial URL"
                              variant="outlined"
                              size="small"
                              value={newVideoUrl}
                              onChange={(e) => {
                                setNewVideoUrl(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip title="Masukkan judul tutorial" followCursor>
                            <TextField
                              label="Tambah Judul"
                              variant="outlined"
                              size="small"
                              value={newVideoTitle}
                              onChange={(e) => {
                                setNewVideoTitle(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Unggah file PDF terkait tutorial"
                            followCursor
                          >
                            <label htmlFor="upload-pdf">
                              <input
                                id="upload-pdf"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setNewVideoPDF(file);
                                    setIsFormIncomplete(false);
                                  }
                                }}
                              />
                              <Button
                                variant="outlined"
                                component="span"
                                fullWidth
                              >
                                Upload File PDF
                              </Button>
                            </label>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan deskripsi tutorial"
                            followCursor
                          >
                            <TextField
                              label="Tambah Deskripsi"
                              variant="outlined"
                              size="small"
                              value={newVideoDescription}
                              onChange={(e) => {
                                setNewVideoDescription(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              // onChange={(e) =>
                              //   setNewVideoDescription(e.target.value)
                              // }
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Mau kamu tambah di menu apa?"
                            followCursor
                          >
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                labelId="menu-label"
                                id="menu-select"
                                value={newMenu || "0"}
                                size="small"
                                displayEmpty
                                fullWidth
                                onChange={(e) => {
                                  setNewMenu(e.target.value);
                                  setIsFormIncomplete(false);
                                }}
                              >
                                <MenuItem value="0" disabled>
                                  Pilih Menu
                                </MenuItem>
                                <MenuItem value="1">PPA Team</MenuItem>
                                <MenuItem value="2">Dashboard PPA</MenuItem>
                                <MenuItem value="3">Dashboard SWI</MenuItem>
                                <MenuItem value="4">Dashboard FMS</MenuItem>
                                <MenuItem value="5">Instalasi Device</MenuItem>
                              </Select>
                            </FormControl>
                          </Tooltip>
                        </Grid>

                        {isButtonVisible && (
                          <Grid item xs={12} sm={6} md={4}>
                            <Tooltip title="Tambah tutorial">
                              <Button
                                variant="contained"
                                onClick={handleAddVideo}
                                size="small"
                                fullWidth
                                endIcon={<SendIcon style={{ fontSize: 28 }} />}
                                disabled={isButtonLoading}
                              >
                                {isButtonLoading ? "Loading..." : "Tambahkan"}
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  ) : (
                    false
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Aplikasi PPA Team
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan aplikasi PPA Team">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                  {
                    <TtPPATeam
                      searchKeyword={searchKeyword}
                      listVideo={listVideo.filter((video) => video.menu == "1")}
                      isAuth={isAuth}
                    />
                  }
                </div>
              }
            />
            {/* tutorial Dashboard PPA */}
            <Route
              path="/dashboard_PPA_Team"
              element={
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0",
                      marginBottom: "0",
                      boxShadow: "rgba(0,0,0,0.1) 0px 8px 8px -8px",
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#blue",
                        },
                      }}
                    >
                      <Tab
                        label="Semua Video"
                        component={Link}
                        to="/semua_video"
                        selected={activeTab === 0}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="PPA Team"
                        component={Link}
                        to="/PPATeam"
                        selected={activeTab === 1}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard PPA"
                        component={Link}
                        to="/dashboard_PPA_Team"
                        selected={activeTab === 2}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard SWI"
                        component={Link}
                        to="/dashboard_SWI"
                        selected={activeTab === 3}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard FMS"
                        component={Link}
                        to="/dashboard_FMS"
                        selected={activeTab === 4}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Instalasi Device"
                        component={Link}
                        to="/instalasi_device"
                        selected={activeTab === 5}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  {isFormIncomplete && (
                    <Alert severity="error" onClose={handleCloseAlert}>
                      Mohon lengkapi semua field sebelum menambahkan video.
                    </Alert>
                  )}
                  {/* Tambah Playlist Tutorial di Dashboard PPA */}
                  {isAuth ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0px",
                        maxWidth: "1350px",
                        margin: "20px auto 0",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        padding: "20px",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="h6" color="textSecondary">
                            Tambah Playlist Tutorial{" "}
                            <Tooltip title="Form penambahan playlist hanya tersedia pada user yang di izinkan saja.">
                              <IconButton color="primary" size="small">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan URL video Youtube yang valid"
                            followCursor
                          >
                            <TextField
                              label="Tambah tutorial URL"
                              variant="outlined"
                              size="small"
                              value={newVideoUrl}
                              onChange={(e) => {
                                setNewVideoUrl(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip title="Masukkan judul tutorial" followCursor>
                            <TextField
                              label="Tambah Judul"
                              variant="outlined"
                              size="small"
                              value={newVideoTitle}
                              onChange={(e) => {
                                setNewVideoTitle(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Unggah file PDF terkait tutorial"
                            followCursor
                          >
                            <label htmlFor="upload-pdf">
                              <input
                                id="upload-pdf"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setNewVideoPDF(file);
                                    setIsFormIncomplete(false);
                                  }
                                }}
                              />
                              <Button
                                variant="outlined"
                                component="span"
                                fullWidth
                              >
                                Upload File PDF
                              </Button>
                            </label>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan deskripsi tutorial"
                            followCursor
                          >
                            <TextField
                              label="Tambah Deskripsi"
                              variant="outlined"
                              size="small"
                              value={newVideoDescription}
                              onChange={(e) => {
                                setNewVideoDescription(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              // onChange={(e) =>
                              //   setNewVideoDescription(e.target.value)
                              // }
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Mau kamu tambah di menu apa?"
                            followCursor
                          >
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                labelId="menu-label"
                                id="menu-select"
                                value={newMenu || "0"}
                                size="small"
                                displayEmpty
                                fullWidth
                                onChange={(e) => {
                                  setNewMenu(e.target.value);
                                  setIsFormIncomplete(false);
                                }}
                              >
                                <MenuItem value="0" disabled>
                                  Pilih Menu
                                </MenuItem>
                                <MenuItem value="1">PPA Team</MenuItem>
                                <MenuItem value="2">Dashboard PPA</MenuItem>
                                <MenuItem value="3">Dashboard SWI</MenuItem>
                                <MenuItem value="4">Dashboard FMS</MenuItem>
                                <MenuItem value="5">Instalasi Device</MenuItem>
                              </Select>
                            </FormControl>
                          </Tooltip>
                        </Grid>
                        {isButtonVisible && (
                          <Grid item xs={12} sm={6} md={4}>
                            <Tooltip title="Tambah tutorial">
                              <Button
                                variant="contained"
                                onClick={handleAddVideo}
                                size="small"
                                fullWidth
                                endIcon={<SendIcon style={{ fontSize: 28 }} />}
                                disabled={isButtonLoading}
                              >
                                {isButtonLoading ? "Loading..." : "Tambahkan"}
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  ) : (
                    false
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Dashboard PPA
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan Dashboard PPA">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                  <TtPageDashPPATeam
                    searchKeyword={searchKeyword}
                    listVideo={listVideo.filter((video) => video.menu == "2")}
                    isAuth={isAuth}
                  />
                </div>
              }
            />
            {/* tutorial dashboard swi */}
            <Route
              path="/dashboard_SWI"
              element={
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0",
                      marginBottom: "0",
                      boxShadow: "rgba(0,0,0,0.1) 0px 8px 8px -8px",
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#blue",
                        },
                      }}
                    >
                      <Tab
                        label="Semua Video"
                        component={Link}
                        to="/semua_video"
                        selected={activeTab === 0}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="PPA Team"
                        component={Link}
                        to="/PPATeam"
                        selected={activeTab === 1}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard PPA"
                        component={Link}
                        to="/dashboard_PPA_Team"
                        selected={activeTab === 2}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard SWI"
                        component={Link}
                        to="/dashboard_SWI"
                        selected={activeTab === 3}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard FMS"
                        component={Link}
                        to="/dashboard_FMS"
                        selected={activeTab === 4}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Instalasi Device"
                        component={Link}
                        to="/instalasi_device"
                        selected={activeTab === 5}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  {isFormIncomplete && (
                    <Alert severity="error" onClose={handleCloseAlert}>
                      Mohon lengkapi semua field sebelum menambahkan video.
                    </Alert>
                  )}
                  {/* Tambah Playlist Tutorial di dashboard SWI */}
                  {isAuth ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0px",
                        maxWidth: "1350px",
                        margin: "20px auto 0",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        padding: "20px",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="h6" color="textSecondary">
                            Tambah Playlist Tutorial{" "}
                            <Tooltip title="Form penambahan playlist hanya tersedia pada user yang di izinkan saja.">
                              <IconButton color="primary" size="small">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan URL video Youtube yang valid"
                            followCursor
                          >
                            <TextField
                              label="Tambah tutorial URL"
                              variant="outlined"
                              size="small"
                              value={newVideoUrl}
                              onChange={(e) => {
                                setNewVideoUrl(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip title="Masukkan judul tutorial" followCursor>
                            <TextField
                              label="Tambah Judul"
                              variant="outlined"
                              size="small"
                              value={newVideoTitle}
                              onChange={(e) => {
                                setNewVideoTitle(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Unggah file PDF terkait tutorial"
                            followCursor
                          >
                            <label htmlFor="upload-pdf">
                              <input
                                id="upload-pdf"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setNewVideoPDF(file);
                                    setIsFormIncomplete(false);
                                  }
                                }}
                              />
                              <Button
                                variant="outlined"
                                component="span"
                                fullWidth
                              >
                                Upload File PDF
                              </Button>
                            </label>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan deskripsi tutorial"
                            followCursor
                          >
                            <TextField
                              label="Tambah Deskripsi"
                              variant="outlined"
                              size="small"
                              value={newVideoDescription}
                              onChange={(e) => {
                                setNewVideoDescription(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              // onChange={(e) =>
                              //   setNewVideoDescription(e.target.value)
                              // }
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Mau kamu tambah di menu apa?"
                            followCursor
                          >
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                labelId="menu-label"
                                id="menu-select"
                                value={newMenu || "0"}
                                size="small"
                                displayEmpty
                                fullWidth
                                onChange={(e) => {
                                  setNewMenu(e.target.value);
                                  setIsFormIncomplete(false);
                                }}
                              >
                                <MenuItem value="0" disabled>
                                  Pilih Menu
                                </MenuItem>
                                <MenuItem value="1">PPA Team</MenuItem>
                                <MenuItem value="2">Dashboard PPA</MenuItem>
                                <MenuItem value="3">Dashboard SWI</MenuItem>
                                <MenuItem value="4">Dashboard FMS</MenuItem>
                                <MenuItem value="5">Instalasi Device</MenuItem>
                              </Select>
                            </FormControl>
                          </Tooltip>
                        </Grid>
                        {isButtonVisible && (
                          <Grid item xs={12} sm={6} md={4}>
                            <Tooltip title="Tambah tutorial">
                              <Button
                                variant="contained"
                                onClick={handleAddVideo}
                                size="small"
                                fullWidth
                                endIcon={<SendIcon style={{ fontSize: 28 }} />}
                                disabled={isButtonLoading}
                              >
                                {isButtonLoading ? "Loading..." : "Tambahkan"}
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  ) : (
                    false
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Dashboard SWI
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan Dashboard SWI">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                  <TtPageDashSWI
                    searchKeyword={searchKeyword}
                    listVideo={listVideo.filter((video) => video.menu == "3")}
                    isAuth={isAuth}
                  />
                </div>
              }
            />
            {/* tutorial Dashboard FMS */}
            <Route
              path="/dashboard_FMS"
              element={
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0",
                      marginBottom: "0",
                      boxShadow: "rgba(0,0,0,0.1) 0px 8px 8px -8px",
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#blue",
                        },
                      }}
                    >
                      <Tab
                        label="Semua Video"
                        component={Link}
                        to="/semua_video"
                        selected={activeTab === 0}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="PPA Team"
                        component={Link}
                        to="/PPATeam"
                        selected={activeTab === 1}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard PPA"
                        component={Link}
                        to="/dashboard_PPA_Team"
                        selected={activeTab === 2}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard SWI"
                        component={Link}
                        to="/dashboard_SWI"
                        selected={activeTab === 3}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard FMS"
                        component={Link}
                        to="/dashboard_FMS"
                        selected={activeTab === 4}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Instalasi Device"
                        component={Link}
                        to="/instalasi_device"
                        selected={activeTab === 5}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  {isFormIncomplete && (
                    <Alert severity="error" onClose={handleCloseAlert}>
                      Mohon lengkapi semua field sebelum menambahkan video.
                    </Alert>
                  )}
                  {/* Tambah Playlist Tutorial di dashboard FMS */}
                  {isAuth ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0px",
                        maxWidth: "1350px",
                        margin: "20px auto 0",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        padding: "20px",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="h6" color="textSecondary">
                            Tambah Playlist Tutorial{" "}
                            <Tooltip title="Form penambahan playlist hanya tersedia pada user yang di izinkan saja.">
                              <IconButton color="primary" size="small">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan URL video Youtube yang valid"
                            followCursor
                          >
                            <TextField
                              label="Tambah tutorial URL"
                              variant="outlined"
                              size="small"
                              value={newVideoUrl}
                              onChange={(e) => {
                                setNewVideoUrl(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip title="Masukkan judul tutorial" followCursor>
                            <TextField
                              label="Tambah Judul"
                              variant="outlined"
                              size="small"
                              value={newVideoTitle}
                              onChange={(e) => {
                                setNewVideoTitle(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Unggah file PDF terkait tutorial"
                            followCursor
                          >
                            <label htmlFor="upload-pdf">
                              <input
                                id="upload-pdf"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setNewVideoPDF(file);
                                    setIsFormIncomplete(false);
                                  }
                                }}
                              />
                              <Button
                                variant="outlined"
                                component="span"
                                fullWidth
                              >
                                Upload File PDF
                              </Button>
                            </label>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan deskripsi tutorial"
                            followCursor
                          >
                            <TextField
                              label="Tambah Deskripsi"
                              variant="outlined"
                              size="small"
                              value={newVideoDescription}
                              onChange={(e) => {
                                setNewVideoDescription(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              // onChange={(e) =>
                              //   setNewVideoDescription(e.target.value)
                              // }
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Mau kamu tambah di menu apa?"
                            followCursor
                          >
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                labelId="menu-label"
                                id="menu-select"
                                value={newMenu || "0"}
                                size="small"
                                displayEmpty
                                fullWidth
                                onChange={(e) => {
                                  setNewMenu(e.target.value);
                                  setIsFormIncomplete(false);
                                }}
                              >
                                <MenuItem value="0" disabled>
                                  Pilih Menu
                                </MenuItem>
                                <MenuItem value="1">PPA Team</MenuItem>
                                <MenuItem value="2">Dashboard PPA</MenuItem>
                                <MenuItem value="3">Dashboard SWI</MenuItem>
                                <MenuItem value="4">Dashboard FMS</MenuItem>
                                <MenuItem value="5">Instalasi Device</MenuItem>
                              </Select>
                            </FormControl>
                          </Tooltip>
                        </Grid>
                        {isButtonVisible && (
                          <Grid item xs={12} sm={6} md={4}>
                            <Tooltip title="Tambah tutorial">
                              <Button
                                variant="contained"
                                onClick={handleAddVideo}
                                size="small"
                                fullWidth
                                endIcon={<SendIcon style={{ fontSize: 28 }} />}
                                disabled={isButtonLoading}
                              >
                                {isButtonLoading ? "Loading..." : "Tambahkan"}
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  ) : (
                    false
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Dashboard FMS
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan Dashboard FMS">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                  <TtPageDashFMS
                    searchKeyword={searchKeyword}
                    listVideo={listVideo.filter((video) => video.menu == "4")}
                    isAuth={isAuth}
                  />
                </div>
              }
            />
            {/* tutorial instalasi Device */}
            <Route
              path="/instalasi_device"
              element={
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0",
                      marginBottom: "0",
                      boxShadow: "rgba(0,0,0,0.1) 0px 8px 8px -8px",
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#blue",
                        },
                      }}
                    >
                      <Tab
                        label="Semua Video"
                        component={Link}
                        to="/semua_video"
                        selected={activeTab === 0}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="PPA Team"
                        component={Link}
                        to="/PPATeam"
                        selected={activeTab === 1}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard PPA"
                        component={Link}
                        to="/dashboard_PPA_Team"
                        selected={activeTab === 2}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard SWI"
                        component={Link}
                        to="/dashboard_SWI"
                        selected={activeTab === 3}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Dashboard FMS"
                        component={Link}
                        to="/dashboard_FMS"
                        selected={activeTab === 4}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <Tab
                        label="Instalasi Device"
                        component={Link}
                        to="/instalasi_device"
                        selected={activeTab === 5}
                        sx={{
                          textTransform: "none",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  {isFormIncomplete && (
                    <Alert severity="error" onClose={handleCloseAlert}>
                      Mohon lengkapi semua field sebelum menambahkan video.
                    </Alert>
                  )}
                  {/* Tambah Playlist Tutorial di instalasi device */}
                  {isAuth ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0px",
                        maxWidth: "1350px",
                        margin: "20px auto 0",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        padding: "20px",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="h6" color="textSecondary">
                            Tambah Playlist Tutorial{" "}
                            <Tooltip title="Form penambahan playlist hanya tersedia pada user yang di izinkan saja.">
                              <IconButton color="primary" size="small">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan URL video Youtube yang valid"
                            followCursor
                          >
                            <TextField
                              label="Tambah tutorial URL"
                              variant="outlined"
                              size="small"
                              value={newVideoUrl}
                              onChange={(e) => {
                                setNewVideoUrl(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip title="Masukkan judul tutorial" followCursor>
                            <TextField
                              label="Tambah Judul"
                              variant="outlined"
                              size="small"
                              value={newVideoTitle}
                              onChange={(e) => {
                                setNewVideoTitle(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Unggah file PDF terkait tutorial"
                            followCursor
                          >
                            <label htmlFor="upload-pdf">
                              <input
                                id="upload-pdf"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setNewVideoPDF(file);
                                    setIsFormIncomplete(false);
                                  }
                                }}
                              />
                              <Button
                                variant="outlined"
                                component="span"
                                fullWidth
                              >
                                Upload File PDF
                              </Button>
                            </label>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Masukkan deskripsi tutorial"
                            followCursor
                          >
                            <TextField
                              label="Tambah Deskripsi"
                              variant="outlined"
                              size="small"
                              value={newVideoDescription}
                              onChange={(e) => {
                                setNewVideoDescription(e.target.value);
                                setIsFormIncomplete(false);
                              }}
                              // onChange={(e) =>
                              //   setNewVideoDescription(e.target.value)
                              // }
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Tooltip
                            title="Mau kamu tambah di menu apa?"
                            followCursor
                          >
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                labelId="menu-label"
                                id="menu-select"
                                value={newMenu || "0"}
                                size="small"
                                displayEmpty
                                fullWidth
                                onChange={(e) => {
                                  setNewMenu(e.target.value);
                                  setIsFormIncomplete(false);
                                }}
                              >
                                <MenuItem value="0" disabled>
                                  Pilih Menu
                                </MenuItem>
                                <MenuItem value="1">PPA Team</MenuItem>
                                <MenuItem value="2">Dashboard PPA</MenuItem>
                                <MenuItem value="3">Dashboard SWI</MenuItem>
                                <MenuItem value="4">Dashboard FMS</MenuItem>
                                <MenuItem value="5">Instalasi Device</MenuItem>
                              </Select>
                            </FormControl>
                          </Tooltip>
                        </Grid>
                        {isButtonVisible && (
                          <Grid item xs={12} sm={6} md={4}>
                            <Tooltip title="Tambah tutorial">
                              <Button
                                variant="contained"
                                onClick={handleAddVideo}
                                size="small"
                                fullWidth
                                endIcon={<SendIcon style={{ fontSize: 28 }} />}
                                disabled={isButtonLoading}
                              >
                                {isButtonLoading ? "Loading..." : "Tambahkan"}
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  ) : (
                    false
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      maxWidth: "1350px",
                      margin: "20px auto 0",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="h5"
                          style={{
                            margin: "0px 20px auto",
                            color: darkMode ? "#f9f9f9" : "#ff9933",
                          }}
                        >
                          Tutorial Instalasi Device
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Berikut adalah tutorial berkaitan dengan Instalasi Device">
                          <IconButton size="small" style={buttonStyle}>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                  <TtInstalasiDevice
                    searchKeyword={searchKeyword}
                    listVideo={listVideo.filter((video) => video.menu == "5")}
                    isAuth={isAuth}
                  />
                </div>
              }
            />
          </Routes>
          <footer
            style={{
              backgroundColor: darkMode ? "#333" : "#f9f9f9",
              paddingTop: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
              // marginTop: "100px",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            <Typography
              variant="body2"
              align="left"
              color="textSecondary"
              style={{ fontSize: "0.875rem" }}
            >
              &copy; {new Date().getFullYear()} <strong>PPA Team</strong> dan
              entitas-entitas terkaitnya. Hak Cipta Dilindungi Undang-Undang.
              Konten bergantung pada ketersediaan.
            </Typography>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.ppa_ops"
                target="_blank"
                rel="noreferrer noopener"
                style={{ marginRight: "10px" }}
              >
                <img src={playstoreImage} alt="Google Play Store" height={30} />
              </a>
              <a
                href="https://apps.apple.com/us/app/ppa-team/id1543801036"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={appstoreImage} alt="App Store" height={30} />
              </a>
            </div>
          </footer>
          <Dialog
            open={logoutDialogOpen}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleLogoutCancel();
              }
            }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              backdropFilter: "blur(8px)",
            }}
          >
            <DialogTitle>Terima Kasih</DialogTitle>
            <DialogContent>
              <Typography>
                Anda akan keluar dari halaman tutorial. Kami harap tutorial yang
                ditampilkan dapat membantu.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                component={Link}
                to="/"
                onClick={handleLogoutConfirm}
                autoFocus
              >
                Keluar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Router>
      {isAddingVideo && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            backdropFilter: "blur(8px)",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      )}
    </ThemeProvider>
  );
};

export default App;
